@import "./image-gallery.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  color: #222;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  padding: 3px 0;
  display: inline-block;
}

label {
  margin-left: 5px;
}


.app-header {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.play-button {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.play-button:after {
  content: "";
  display: block;
  position: absolute;
  top: 16.5px;
  left: 40px;
  margin: 0 auto;
  border-style: solid;
  border-width: 12.5px 0 12.5px 20px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 1);
}

.close-video::before {
  content: '✖';
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  padding: 20px;
  z-index: 1;
  line-height: .7;
  display: block;
  color: #fff;
}

.video-wrapper {
  position: relative;
  padding: 33.35% 0; /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.app .image-gallery,
.app-sandbox {
  background-color: transparent;
  margin: 0 auto;
  width: 90%;
  margin-top: 50px;
}

@media (max-width: 1320px) {
  .app-sandbox-content {
    padding: 0 20px;
  }
}

.app-sandbox {
  margin: 40px auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.app-buttons li {
  display: block;
}

@media (max-width: 768px) {

  .app-header {
    font-size: 20px;
  }

  .app-buttons li {
    display: block;
    margin: 10px 0;
  }

  .app-buttons li + li {
    padding: 0;
  }

  .play-button {
    height: 40px;
    width: 65px;
  }

  .play-button:after {
    top: 11px;
    left: 27px;
    border-width: 8.5px 0 8.5px 12px;
  }

  .close-video::before {
    font-size: 16px;
    padding: 15px;
  }
}

@media (max-width: 1024px) {
  .app .image-gallery,
  .app-sandbox {
    width: 100%;
  }
}

.app-interval-input-group {
  display: table;
}

.app-interval-label {
  display: table-cell;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 3px solid #ccc;
  border-right: none;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.app-interval-input {
  -webkit-appearance: none;
  display: table-cell;
  margin: 0;
  padding: 9px;
  border-radius: 5px;
  font-size: 14px;
  border: 3px solid #ccc;
  background: #fff;
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input.app-interval-input {
  width: 65px;
}

.app-checkboxes {
  margin-top: 10px;
}

.app-checkboxes li {
  display: block;
}

